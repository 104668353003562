<script setup lang="ts">
const userStore = useUserStore();
const localePath = useLocalePath();
</script>

<template>
  <NuxtLayout>
    <template #header-title>
      ️Bonjour {{ userStore.currentUser?.displayName }} ☀️
    </template>

    <template #header-cta>
      <BaseButton
        type="primary"
        text="Mon nutritionniste"
        icon="material-symbols:favorite-rounded"
        @click="navigateTo(localePath('/'))"
      ></BaseButton>
      <BaseButton
        type="primary"
        text="Ajouter un suivi alimentaire"
        icon="fluent:food-apple-20-filled"
        @click="navigateTo(localePath('/food-survey/add'))"
      ></BaseButton>
      <BaseButton
        type="primary"
        text="Ajouter une pesée"
        icon="ion:scale"
      ></BaseButton>
    </template>

    <div class="home-patient p-4">
      <slot></slot>
    </div>
  </NuxtLayout>
</template>

<style scoped></style>
